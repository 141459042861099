import React from 'react';
import { Row, Col, Card, Badge, Modal, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const FactCard = (props) => {
  let [modalOpen, setModalOpen] = React.useState(false);

  function toggle() {
    setModalOpen(!modalOpen);
  }

  function copyLink() {
    navigator.clipboard.writeText(props.shareLink);
    toast.success('Link Copied!', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });
  }

  return (
    <Card className="fact-card">
      <Button className="factcard-button-header">
        <div className="float-right">
          {/* <FontAwesomeIcon size="lg" icon={['fab', 'facebook-square']} className="factcard-button-header-icon text-light pointer" /> &nbsp;&nbsp;
          <FontAwesomeIcon size="lg" icon={['fab', 'twitter']} className="factcard-button-header-icon text-light pointer" /> &nbsp;&nbsp; */}
          <FontAwesomeIcon
            size="lg"
            icon="share-alt"
            className="factcard-button-header-icon text-light pointer"
            onClick={copyLink}
          />{' '}
          &nbsp;&nbsp;
          {/* <FontAwesomeIcon size="lg" icon="expand-arrows-alt" className="text-light pointer" /> &nbsp;&nbsp; */}
        </div>
      </Button>
      <Modal isOpen={modalOpen} toggle={toggle} className="modal-dialog-centered">
        <LazyLoadImage src={props.image} alt="Img" className="img-fluid rounded shadow-lg" />
      </Modal>
      <Row>
        <Col xs="12" md="3" lg="3" className="fact-card-img">
          {props.image ? (
            <LazyLoadImage
              onClick={toggle}
              src={props.image}
              alt="Img"
              className="img-fluid rounded shadow-lg"
              style={{ width: 200 }}
            />
          ) : (
            ''
          )}
        </Col>

        <Col xs="12" md="9" lg="9">
          <div className="fact-body">
            <blockquote className="blockquote">
              <h6>{props.claimBy ? `Claim By: ${props.claimBy}` : ``} </h6>
              <p className="claimText">“{props.claimText}”</p>
              <Badge
                style={{ whiteSpace: 'normal', lineHeight: 'normal' }}
                className="text-left"
                color={props.ratingColor}
                pill
              >
                {props.ratingBy} Rating: <strong>{props.rating}</strong>
              </Badge>{' '}
            </blockquote>
            <hr className="fact-hr"></hr>
            {props.linkText ? (
              <p className="fact-text">
                {props.linkText} <FontAwesomeIcon size="lg" icon="check" className="text-success" />
              </p>
            ) : (
              ''
            )}
            <a href={props.linkUrl} target="_blank">
              <small>
                <strong>
                  {props.linkUrl} <FontAwesomeIcon icon="external-link-alt" className="text-info" />
                </strong>
              </small>
            </a>

            <div className="mb-3 text-right">
              <p className="text-muted text-left float-left">
                <small>Feb 14, 2022</small>
              </p>
              <Badge style={{ whiteSpace: 'normal' }} className="text-left pointer" color={props.ratingColor}>
                {props.linkDomain}
              </Badge>{' '}
              <Badge style={{ whiteSpace: 'normal' }} className="text-left pointer" color={props.ratingColor}>
                {props.claimBy}
              </Badge>
            </div>
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default FactCard;

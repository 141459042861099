import React from 'react';
import { Container } from 'reactstrap';
import MainNavbar from '../components/presentation/MainNavbar';
import ResultsHeader from '../components/presentation/ResultsHeader';
import ResultsBody from '../components/presentation/ResultsBody';

const Results = (props) => {
  let [searchTextChange, setSearchTextChange] = React.useState(0);
  let [newSearch, setNewSearch] = React.useState(true);
  let [searchText, setSearchText] = React.useState(new URLSearchParams(window.location.search).get('q'));

  const updateSearchText = (text) => {
    const rand = Math.floor(Math.random() * (100 - 1) + 1)
    setNewSearch(true)
    setSearchTextChange(rand);
    setSearchText(text)
  };

  return (
    <div className="index">
      <MainNavbar />
      <ResultsHeader updateSearchText={updateSearchText} />
      <Container>
        <ResultsBody searchTextChange={searchTextChange} searchText={searchText} newSearch={newSearch} setNewSearch={setNewSearch}  />
      </Container>
    </div>
  );
};

export default Results;

import React from 'react';
import { Container } from 'reactstrap';

const Footer = (props) => {
  return (
    <footer className="footer-1 bg-light text-dark">
      <Container>
        <div className="d-flex flex-column flex-md-row justify-content-between align-items-center">
          <div className="links">
            <ul className="footer-menu list-unstyled d-flex flex-row text-center text-md-left">
              <li>
                <a href="/">Search</a>
              </li>
              <li>
                <a href="/about">About</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="text-center">
          <hr />
          <p className="small">2022, ReTrue.Org - Open Global Fact Check Explorer</p>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;

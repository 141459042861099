import React from 'react';
import MainNavbar from '../components/presentation/MainNavbar';
import AboutBody from '../components/presentation/AboutBody';

class About extends React.Component {
  render() {
    document.title = 'ReTrue.Org | About';

    return (
      <div className="index">
        <MainNavbar />
        <AboutBody />
      </div>
    );
  }
}

About.defaultProps = {
  title: 'About',
  description: 'ReTrue.Org - Open Fact Check - Global Fact Check Explorer.',
};

export default About;

import React, { useEffect } from 'react';
import { Container, Row, Col, FormGroup, Label, Input, Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet';

const ResultsHeader = (props) => {
  let searchInput = React.createRef();
  let history = useHistory();
  let [canonical, setCanonical] = React.useState(false);
  let [searchText, setSearchText] = React.useState(false);

  useEffect(() => {
    const searchText = new URLSearchParams(window.location.search).get('q');
    setSearchText(searchText);
    searchInput.current.value = searchText;
    setCanonical(`https://retrue.org/results?q=${searchText}`);
  }, [searchInput, searchText]);

  const handleSearchEnter = (e) => {
    if (e.key === 'Enter') {
      handleClick();
    }
  };

  const handleClick = (e) => {
    const newSearchText = searchInput.current.value;
    if (newSearchText === searchText) return false;
    history.replace(`/results?q=${newSearchText}`);
    props.updateSearchText(newSearchText);
  };

  return (
    <div className="bg-primary text-white" id="banner">
      {canonical ? (
        <Helmet>
          <title>ReTrue.Org Search - {searchText}</title>
          <link rel="canonical" href={canonical} />
        </Helmet>
      ) : (
        ''
      )}
      <div className="bubbles d-none d-md-block">
        <div className="bubble bubble-1 rotate-bubble bg-warning"></div>
        <div className="bubble bubble-4 bubble-top-left bg-info rotate-bubble"></div>
        <div className="bubble bubble-5 bg-info rotate-bubble"></div>

        <div className="circle circle-1 bg-light rotate-circle"></div>
        <div className="circle circle-3 bg-danger rotate-circle"></div>
        <div className="circle circle-4 bg-info rotate-circle"></div>
        <div className="circle circle-7 bg-warning rotate-circle"></div>
        <div className="circle circle-8 bg-white rotate-circle"></div>
        <div className="circle circle-9 bg-warning rotate-circle"></div>
        <div className="circle circle-10 bg-danger rotate-circle"></div>
      </div>
      <Container>
        <Row>
          <Col xs="12" lg={{ size: 8, offset: 2 }}>
            <div className="title">
              <h4 className="text-white">ReTrue.Org</h4>
              <div className="form-inline mb-3">
                <FormGroup className="flex-grow-1">
                  <Label className="sr-only" for="email4">
                    Search
                  </Label>
                  <Input
                    onKeyDown={handleSearchEnter}
                    innerRef={searchInput}
                    type="text"
                    bsSize="lg"
                    className="flex-grow-1 mr-2 input-dark"
                    id="search-text"
                    placeholder="Fact check here for a topic or a person ..."
                  />
                </FormGroup>
                <Button onClick={handleClick} color="primary" size="lg" className=" mt-0 mt-md-3 mt-lg-0">
                  {' '}
                  <FontAwesomeIcon className="mr-2" icon="search" />
                  Search
                </Button>
              </div>
              <h6 className="text-success">
                {' '}
                <FontAwesomeIcon icon="check" /> Open Global Fact Check Explorer
              </h6>
            </div>
            <div className="empty-space-3"></div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ResultsHeader;

import React from 'react';
import Autocomplete from 'react-autocomplete';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';

const Search = (props) => {
  let [value, setValue] = React.useState();
  let [items, setItems] = React.useState([]);

  let searchInput = React.createRef();
  const handleAutoComplete =  () => {
    const searchText = value;
    if (!searchText) return false;
    const local = window.location.hostname === 'localhost';
    const searchHost = local ? 'http://localhost' : 'https://sdrf4rt87yuhjnal45hbgvb.herokuapp.com';
    fetch(`${searchHost}/autocomplete?hl=en&&q=${searchText}`, {
      headers: {
        Referer: searchHost,
      },
    })
      .then((response) => response.text())
      .then((response) => {
        const l0 = response.replace(`)]}'`, '');
        const l1 = JSON.parse(l0);
        const autoList = [value, ..._.map(l1[0][1], '[0]')];
        setItems(autoList);
      });
  }

  const handleSearchEnter = (e) => {
    if (e.key === 'Enter') {
      return props.handleClick(value);
    }
  };

  return (
    <Autocomplete
      wrapperStyle={{ display: 'contents' }}
      inputProps={{
        placeholder: 'Fact check here for a topic or a person ...',
        className: 'flex-grow-1 mr-2 input-dark form-control',
        id: 'search-text-auto',
        onKeyDown: handleSearchEnter,
      }}
      getItemValue={(item) => item}
      items={items}
      renderItem={(item, isHighlighted) => (
        <div
          key={item}
          style={{
            background: isHighlighted ? 'lightgray' : 'white',
            color: 'black',
            paddingLeft: '20px',
            paddingTop: '5px',
            paddingBottom: '5px',
          }}
        >
          {item === 'loader' ? (
            <FontAwesomeIcon
              size="lg"
              icon="circle-notch"
              className="factcard-button-header-icon text-light pointer fa-spin"
            />
          ) : (
            ''
          )}{' '}
          {item === 'loader' ? value : item}
        </div>
      )}
      menuStyle={{
        fontSize: '90%',
        position: 'fixed',
        marginRight: '10px',
        overflow: 'auto',
        borderRadius: '3px',
      }}
      ref={searchInput}
      value={value}
      onChange={(e) => {
        if (items[0] !== 'loader') {
          setItems(['loader', ...items]);
        }
        props.setSearchText(e.target.value);
        setValue(e.target.value);
        handleAutoComplete();
      }}
      onSelect={(val) => {
        props.setSearchText(val);
        setValue(val);
      }}
    />
  );
};

export default Search;

import React from 'react';
import PropTypes from 'prop-types';
import { Collapse, Container, Navbar, NavbarToggler, Nav, NavItem } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import logo from './../../assets/img/ReTrue-Logo.png';

class MainNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
    };
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  render() {
    return (
      <div>
        <Navbar className="navbar-dark bg-primary" expand="lg">
          <Container>
            <NavLink to="/" className="navbar-brand">
              <img src={logo} alt="logo" className="navbar-brand-logo"  />
            </NavLink>
            <NavbarToggler onClick={this.toggle} />
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="ml-auto" navbar style={{float: 'right'}}>
                <NavItem key="navitem-3">
                  <NavLink className="btn btn-outline-light" to="/about">
                    About
                  </NavLink>
                </NavItem>
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </div>
    );
  }
}

MainNavbar.propTypes = {
  title: PropTypes.string,
};

MainNavbar.defaultProps = {
  title: 'ReTrue.Org',
};

export default MainNavbar;
